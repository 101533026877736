// ReSharper disable InconsistentNaming
import 'babel-polyfill';
import { getField, updateField } from 'vuex-map-fields';

const state = {
    visible: false,
    text: null,
    timeout: 3000,
    multiline: false,
    type: null
};

const getters = {
    getField
};

const actions = {
};

const mutations = {
    updateField,

    notify: (state, payload) => {
        if (payload.text) {
            state.text = payload.text;
        } else {
            // something isn't calling with a notificaton message. bail
            return;
        }

        state.multiline = state.text.length > 50 || 'multiline' in payload;

        if ('type' in payload) {
            state.type = payload.type;
        }

        if ('timeout' in payload) {
            state.timeout = payload.timeout;
        }

        state.visible = true;
    },

    closeNotification: state => {
        state.visible = false;
        state.timeout = 3000;
        state.text = null;
        state.type = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}