<template>
    <v-snackbar id="notifications"
                top right
                v-model="visible"
                :timeout="0"
                :multi-line="multiline">
        <div class="icon-border" :class="type">
            <v-icon medium dark v-html="type" />
        </div>
        <div id="notificationMessage" class="message" v-html="text"/>
        <v-btn id="closeNotification" flat @click.native="closeNotification">
            <v-icon medium>close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { mapFields } from 'vuex-map-fields';

    export default {
        computed: {
            ...mapFields('notifications', [
                'visible', 'timeout', 'multiline', 'text', 'type'
            ])
        },

        methods: {
            ...mapMutations('notifications',
                ['closeNotification', 'notify'])
        }
    }
</script>