// ReSharper disable InconsistentNaming
import 'babel-polyfill';

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import nprogress from 'nprogress';
import identityApi from '../api';

store.dispatch('identities/initializeIdentities');

Vue.use(VueRouter);
const routes = [
    {
        path: '/app',
        redirect: { name: 'login-chooser' }
    },
    {
        path: '/app/login',
        name: 'login-chooser',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Login/UserChooser.vue'),
        beforeEnter: (to, from, next) => {
            if (store.getters['identities/count'] > 0) {
                next();
            } else {
                next({ name: 'login-username' });
            }
        }
    },
    {
        path: '/app/samlChooser',
        name: 'saml-chooser',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Login/SamlChooser.vue'),
        beforeEnter: (to, from, next) => {
            if (to.params.providers && to.params.username) {
                next();
            } else {
                next({ name: 'login-chooser' });
            }
        }
    },
    {
        path: '/app/login/username',
        name: 'login-username',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Login/Username.vue'),
        beforeEnter: (to, from, next) => {
            next(vm => {
                vm.validation = [];
            });

        }
    },
    {
        path: '/app/login/password',
        name: 'login-password',
        component: () => import(
            /* webpackChunkName: "app.bundle" */
            '../components/Login/Password.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
            if (to.params.selectedUsername) {
                next();
            } else {
                next({ name: 'login-chooser' });
            }
        },
        beforeLeave(to, from, next) {
            Object.assign(this.$data, this.$options.data());
            this.$refs.password.reset();
            this.error = false;
            next();
        }
    },
    {
        path: '/app/login/remove-user',
        name: 'chooser-remove',
        component: () => import(
            /* webpackChunkName: "app.bundle" */
            '../components/Login/RemoveUser.vue'),
        props: true
    },
    {
        path: '/app/profile/password',
        name: 'password-change',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/ChangePassword.vue')
    },
    {
        path: '/app/password/forgot',
        name: 'password-forgot',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/ForgotPassword.vue'),
        props: true
    },
    {
        path: '/app/password/reset',
        name: 'password-reset',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/ResetPassword.vue'),
        props: route => route.meta.props,
        beforeEnter: (to, from, next) => {
            if (to.query.token) {
                identityApi.resetPassword.get({
                    params: {
                         token: to.query.token
                    },
                    verifyRequest: true
                })
                    .then(r => {
                        to.meta.props = r.data;
                        to.meta.props.resetToken = to.query.token;
                        next();
                    }).catch(error => {
                        const errorMessage = error.response.data.errors.map(e => e.message).join('</br>');
                        next({ name: 'password-forgot' });
                        store.commit('notifications/notify', { text: errorMessage, type: 'error', timeout: 0 });
                    });
            } else {
                next({ name: 'login-chooser' });
            }
        }
    },
    {
        path: '/app/activate',
        name: 'activate',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Activation/Activate.vue'
            ),
        props: route => route.meta.props
    },
    {
        path: '/app/emailResend',
        name: 'email-resend',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/EmailResend.vue'
            ),
        props: true
    },
    {
        path: '/app/logout',
        name: 'logout',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Logout.vue'
            ),
        props: route => route.meta.props,
        beforeEnter: (to, from, next) => {
            const logout = logoutId => {
                identityApi.logout.post({
                    payload: { LogoutId: logoutId }
                })
                    .then(response => {
                        store.commit("logout/spLoggedOut", true);
                        to.meta.props = {
                            initialPostLogoutRedirectUri: response.data.postLogoutRedirectUri,
                            initialSignOutIframeUrl: response.data.signOutIframeUrl,
                            initialClientName: response.data.clientName,
                            initialLoggedOut: true,
                            samlLogoutUrl: to.query.samlLogoutUrl
                        };

                        next();
                        if(to.query.samlLogoutUrl) {
                            setTimeout(() => {
                                window.location = to.query.samlLogoutUrl
                            }, 5000);
                        }
                        if (response.data.postLogoutRedirectUri) {
                            setTimeout(() => {
                                window.location = response.data.postLogoutRedirectUri;
                            }, 5000);
                        }

                        setTimeout(() => {
                            store.commit("logout/spLoggedOut", false);
                        }, 5000);
                    });
            };

            if (to.query.showLogoutPrompt) {
                try {
                    if (JSON.parse(to.query.showLogoutPrompt.toLowerCase())) {
                        // we need to manually log out
                        to.meta.props = to.query;
                        next();
                    } else {
                        // automatically log out
                        logout(to.query.logoutId);
                    }
                } catch (error) {
                    next({ name: 'login-chooser' });
                }
            } else {
                next({ name: 'login-chooser' });
            }
        }
    },
    {
        path: '/app/signup',
        name: 'signup',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Signup.vue'
            ),
        beforeLeave: (to, from, next) => {
            this.$refs.form.reset();
            this.error = false;
            this.recaptchaResponse = null;

            next();
        }
    },
    {
        path: '/app/user/verify',
        name: 'import',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/Import.vue'
            ),
        beforeLeave: (to, from, next) => {
            this.$refs.form.reset();
            this.error = false;
            next();
        }
    },
    {
        path: '*',
        component: () =>
            import(
                /* webpackChunkName: "app.bundle" */
                '../components/NotFound.vue'
            )
    }
];

const router = new VueRouter({
    mode: 'history',
    routes: routes
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        nprogress.start();
    }
    next();
});

router.afterEach(() => {
    nprogress.done();
});

export default router;
