var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "application", attrs: { "data-app": "true", id: "app" } },
    [
      _c("Notifications"),
      _vm._v(" "),
      _c(
        "v-layout",
        [
          _c(
            "transition",
            { attrs: { name: "slide-x-reverse-transition", mode: "out-in" } },
            [
              _c(
                "keep-alive",
                [
                  _c("router-view", {
                    on: {
                      notify: _vm.notify,
                      "remember-route": _vm.rememberRoute
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }