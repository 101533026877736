<template>
    <div data-app="true" id="app" class="application">
        <Notifications />
        <v-layout>
            <transition name="slide-x-reverse-transition" mode="out-in">
                <keep-alive>
                    <router-view v-on:notify="notify" v-on:remember-route="rememberRoute" />
                </keep-alive>
            </transition>
        </v-layout>
    </div>
</template>

<script>
    import Notifications from './components/Notifications.vue';

    export default {
        mixins: [Notifications],

        data() {
            return {
                returnRoute: ""
            }
        },

        components: {
            Notifications
        },

        methods: {
            rememberRoute(route) {
                this.returnRoute = route;
            }
        }
    }
</script>