// ReSharper disable InconsistentNaming
import IdentityEndpoint from './IdentityEndpoint';

export default class {
    constructor(dudeApiInterface) {
        this.interface = dudeApiInterface;
        this.heartbeat = new IdentityEndpoint(this.interface, '/heartbeat', ['get']);
        this.changePassword = new IdentityEndpoint(this.interface, '/profile/password', ['get', 'post']);
        this.resetPassword = new IdentityEndpoint(this.interface, '/password/reset', ['get', 'post']);
        this.activate = new IdentityEndpoint(this.interface, '/activate', ['get', 'post']);
        this.verifyEmailRequest = new IdentityEndpoint(this.interface, '/emailVerificationRequests', ['post']);
        this.verifyEmail = new IdentityEndpoint(this.interface, '/emailVerifications', ['post']);
        this.logout = new IdentityEndpoint(this.interface, '/auth/logout', ['get', 'post']);
        this.impersonate = new IdentityEndpoint(this.interface, '/users/{subjectId}/impersonate', ['get']);
        this.forgotPassword = new IdentityEndpoint(this.interface, '/password/forgot', ['post']);
        this.login = new IdentityEndpoint(this.interface, '/auth/login', ['post']);
        this.getLoginMethods = new IdentityEndpoint(this.interface, '/auth/username', ['post']);
        this.signups = new IdentityEndpoint(this.interface, '/signups', ['post']);
        this.import = {
            verify: new IdentityEndpoint(this.interface, '/users/{subjectId}/verify', ['post']),
            validateToken: new IdentityEndpoint(this.interface, '/import/validate', ['get'])
        }
    }
}
