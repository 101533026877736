// ReSharper disable InconsistentNaming
import IdentityAxiosInterface from './IdentityAxiosInterface';
import IdentityClient from './IdentityClient';
import Axios from 'axios';

const axios = new Axios.create();

axios.interceptors.response.use(
    config => config,
    error => {
        var errorResponse = { errors: [] };

        if (error.response && error.response.data.validationErrors) {
            errorResponse.errors = error.response.data.validationErrors;
        }
        else {
            errorResponse.errors.push({ message: error.message, code: 'HttpBadRequest' });
        }

        error.response.data = errorResponse;

        return Promise.reject(error);
    }
);

const identityInterface = new IdentityAxiosInterface(axios);

export default new IdentityClient(identityInterface);