// ReSharper disable InconsistentNaming
import 'babel-polyfill';

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    knownIdentities: []
};

const getters = {
    count: state => {
        return state.knownIdentities.length;
    }
};

const actions = {
    initializeIdentities: ({ commit, state }) => {
        let knownIdentities = localStorage.getItem('known-identities') || [];

        const identitiesSorter = function (u1, u2) {
            if (u1.givenName && u2.givenName) {
                return u1.givenName.localeCompare(u2.givenName);
            }

            return u1.username.localeCompare(u2.username);
        };

        try {
            knownIdentities = JSON.parse(knownIdentities);

            if (Array.isArray(knownIdentities)) {
                for (const [index, userData] of knownIdentities.entries()) {
                    if (!(userData.hasOwnProperty('username') &&
                        userData.hasOwnProperty('givenName') &&
                        userData.hasOwnProperty('familyName'))) {
                        knownIdentities.splice(index, 1);
                    }
                }
            }
        } catch (e) {
            knownIdentities = [];
        }

        commit('setIdentities', knownIdentities);
    },

    removeIdentityFromKnownIdentities: ({ state, commit }, index) => {
        var identities = state.knownIdentities;

        identities.splice(index, 1);

        commit('setIdentities', identities);
    },

    upsertIdentityToKnownIdentities({ state, commit }, identity) {
        const index = state.knownIdentities.findIndex(existingIdentity => existingIdentity.username === identity.username);

        if (index !== -1) {
            commit('updateIdentity', identity);
        } else {
            commit('pushIdentityToIdentities', identity);
        }
    }
};

const mutations = {
    setIdentities: (state, identities) => {
        state.knownIdentities = identities;
    },

    updateIdentity: (state, identity) => {
        const index = state.knownIdentities.findIndex(existingIdentity => existingIdentity.username === identity.username);
        state.knownIdentities[index] = identity;
    },

    pushIdentityToIdentities: (state, identity) => {
        state.knownIdentities.push(identity);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}