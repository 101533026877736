// ReSharper disable InconsistentNaming
import 'babel-polyfill';

import Vue from 'vue';
import Vuex from 'vuex';

import identities from './modules/identities';
import notifications from './modules/notifications';
import logout from './modules/logout';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        identities,
        notifications,
        logout
    }
});

store.subscribe((mutation, state) => {
    localStorage.setItem('known-identities', JSON.stringify(state.identities.knownIdentities));
});

export default store;
