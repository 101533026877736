var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        id: "notifications",
        top: "",
        right: "",
        timeout: 0,
        "multi-line": _vm.multiline
      },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "div",
        { staticClass: "icon-border", class: _vm.type },
        [
          _c("v-icon", {
            attrs: { medium: "", dark: "" },
            domProps: { innerHTML: _vm._s(_vm.type) }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "message",
        attrs: { id: "notificationMessage" },
        domProps: { innerHTML: _vm._s(_vm.text) }
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { id: "closeNotification", flat: "" },
          nativeOn: {
            click: function($event) {
              return _vm.closeNotification($event)
            }
          }
        },
        [_c("v-icon", { attrs: { medium: "" } }, [_vm._v("close")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }