// ReSharper disable InconsistentNaming
import urlTemplate from 'url-template';

class IdentityEndpoint {
    constructor(dudeInterface, path, verbs) {
        for (let verb of verbs) {
            this[verb] = (options = {}) => {
                var resource = path;

                var xsrfToken = document.querySelector('main[data-xsrf-token]').dataset.xsrfToken;

                if (verb === 'post') {
                    // if we are posting always add the xsrf token
                    options.headers = { RequestVerificationToken: xsrfToken };
                }

                if (verb === 'get' && options.verifyRequest) {
                    // allow gets to optionally add the header with verifyRequest
                    options.headers = { RequestVerificationToken: xsrfToken };
                    delete options.verifyRequest;
                }

                if (options.urlParams) {
                    const template = urlTemplate.parse(resource);
                    resource = template.expand(options.urlParams);
                    delete options.urlParams;
                }

                return dudeInterface[verb](resource, options);
            };
        }
    }
}

export default IdentityEndpoint;
