// ReSharper disable InconsistentNaming
import 'babel-polyfill';

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    spLoggingOut: false
};

const getters = {
    count: state => {
        return state.spLoggingOut;
    }
};

const mutations = {
    spLoggedOut: (state, spLoggingOut) => {
        state.spLoggingOut = spLoggingOut;
    },
}

export default {
    namespaced: true,
    state,
    mutations
}